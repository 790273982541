<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import imageCompression from "browser-image-compression";
  import { format as formatDate } from "date-fns";
  import { onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import sample from "~/assets/images/image_photo_sample.jpg";
  import sceneUnloadingPackage from "~/assets/images/sceneUnloadingPackage.png";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import { beep, destroyAudioContext, initAudioContext } from "~/libs/audio";
  import { parseQRCodeEmbeddedDeliveryInfo } from "~/libs/base45DecodeUtils";
  import {
    AVAILABLE_DROP_OFF_LOCATIONS,
    DropOffLocation,
    STATUS_DELIVERED,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import offlineBackendApi from "~/libs/offlineBackendApi";
  import { CodeType } from "~/libs/qrCodeScanner";
  import { deliveryTarget } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";
  import DeliveryCompletionProcedure from "~/pages/Update/DeliveryCompletionProcedure.svelte";

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物情報 */
  let deliveryPackage;

  /** @type {number} 宅配ドライバーが選択した受け渡し方法（下位コンポーネントからのbind用） */
  let actualPackageDropPlace;
  /** @type {string} 宅配ボックスのボックス番号（下位コンポーネントからのbind用） */
  let lockerNumber;
  /** @type {string} 宅配ボックスの暗証番号（下位コンポーネントからのbind用） */
  let lockerPin;

  /** @type {File} 配達証明用の写真ファイル */
  let photoFileForProofOfDelivery;
  /** @type {string} 配達証明用の写真のデータURL */
  let photoDataUrlForProofOfDelivery;
  /** @type {boolean} 汚損フラグ（チェックボックス） */
  let damaged = false;

  /** @type {boolean} 送り状のQRコードスキャンが完了したか否か */
  let qrCodeScanCompleted = false;

  /** @type {QrCodeScanner} QrCodeScannerコンポーネントのインスタンス */
  let qrCodeScanner;
  /** @type {boolean} QRコードを読み取り中か否か */
  let qrCodeScanInProgress = false;
  /** @type {Set<string>} 読み取り済の送り状番号の一覧 */
  const scannedTrackingNumbers = new Set();

  let helpPageOpen = false;

  /** @type {boolean} 配達完了手続に進めない入力状態の場合はtrue */
  $: disallowGoAheadToDeliveryCompletion =
    !judgeAllowGoAheadToDeliveryCompletionetion(
      // @ts-ignore
      actualPackageDropPlace,
      lockerNumber,
      lockerPin,
      photoFileForProofOfDelivery,
    );

  /**
   * 配達完了手続に進める入力状態か否かを判定する
   * @returns {boolean} 配達完了手続に進める入力状態か否か
   */
  function judgeAllowGoAheadToDeliveryCompletionetion() {
    if (!AVAILABLE_DROP_OFF_LOCATIONS.includes(actualPackageDropPlace)) {
      // 利用可能な受け渡し方法以外はNG
      return false;
    }
    if (actualPackageDropPlace !== DropOffLocation.HANDOVER) {
      if (!photoFileForProofOfDelivery) {
        // 手渡し以外（置き配）は写真が必須
        return false;
      }
      if (actualPackageDropPlace === DropOffLocation.LOCKER) {
        if (lockerNumber != null && !lockerNumber) {
          // 「ボックス番号を指定」がONの場合は必須
          return false;
        }
        if (lockerPin != null && !lockerPin) {
          // 「暗証番号を指定」がONの場合は必須
          return false;
        }
      }
    }
    return true;
  }

  onDestroy(() => {
    destroyAudioContext();
  });

  /**
   * スキャン成功時の処理
   * @param {string} decodedText QRコードから読み取ったテキスト
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  function onScanSuccess(decodedText, needsDecode, codeType) {
    const currentScannedTrackingNumbersSize = scannedTrackingNumbers.size;

    /** @type {string} */
    let scannedTrackingNumber = decodedText;
    let scannedDeliveryInfo = null;
    let isManuallyInputted = false;
    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        if (codeType === CodeType.QRCODE) {
          scannedTrackingNumber =
            parseQRCodeEmbeddedTrackingNumber(decodedText);
          scannedDeliveryInfo = parseQRCodeEmbeddedDeliveryInfo(decodedText);
        } else {
          scannedTrackingNumber =
            parseCodabarEmbeddedTrackingNumber(decodedText);
        }
        console.log(scannedDeliveryInfo);
      } else {
        isManuallyInputted = true;
      }
      if (scannedDeliveryInfo == null) {
        scannedDeliveryInfo = {
          desiredDeliveryMethod: null,
          availableDeliveryMethod: [
            DropOffLocation.BY_THE_DOOR,
            DropOffLocation.LOCKER,
            DropOffLocation.METERBOX,
            DropOffLocation.MAILBOX,
            DropOffLocation.HANDOVER,
          ],
          receiverSelectableDeliveryMethod: [
            DropOffLocation.BY_THE_DOOR,
            DropOffLocation.LOCKER,
            DropOffLocation.METERBOX,
            DropOffLocation.MAILBOX,
            DropOffLocation.HANDOVER,
          ],
        };
      }
    } catch (error) {
      parseError = error;
    } finally {
      scannedTrackingNumbers.add(scannedTrackingNumber);
    }

    if (scannedTrackingNumbers.size === currentScannedTrackingNumbersSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      toast.error(parseError.message);
      return;
    }

    beep();
    qrCodeScanner.stopScanning().then(() => {
      deliveryPackage = {
        trackingNumber: scannedTrackingNumber,
        packageDropPlace: scannedDeliveryInfo.desiredDeliveryMethod,
        customer: {
          availableDeliveryMethod: scannedDeliveryInfo.availableDeliveryMethod,
          receiverSelectableDeliveryMethod:
            scannedDeliveryInfo.receiverSelectableDeliveryMethod,
        },
        isManuallyInputted: isManuallyInputted,
        cashOnDeliveryRequred: scannedDeliveryInfo.cashOnDeliveryRequred,
      };
      deliveryTarget.set(deliveryPackage);
      damaged = false;
      qrCodeScanInProgress = false;
      qrCodeScanCompleted = true;
      scannedTrackingNumbers.clear();
    });
  }

  /**
   * スキャンエラー時の処理
   * @param {string} errorMessage
   * @param {Error | ?} error
   */
  function onScanError(errorMessage, error) {
    console.log("onScanError:", errorMessage, error);
  }

  /**
   * 写真のアップロード処理
   * @param {Event} event
   */
  async function photoChange(event) {
    /** @type {FileList} */
    const files = /** @type {HTMLInputElement} */ (event.target).files;
    if (files.length === 0) {
      return;
    }

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try {
      photoFileForProofOfDelivery = await imageCompression(files[0], options);
    } catch (error) {
      logger.error(
        "[OfflineUpdate] 画像の圧縮・変換時にエラーが発生しました",
        {
          name: files[0].name,
          type: files[0].type,
          size: files[0].size,
        },
        error,
      );
      toast.error($_("errors.failedFormatConversion"), { duration: 10000 });
      photoDataUrlForProofOfDelivery = null;
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      photoDataUrlForProofOfDelivery = /** @type {string} */ (e.target.result);
    };

    reader.readAsDataURL(photoFileForProofOfDelivery);
  }

  /**
   * 配送ステータス更新APIのリクエストをキューに登録する
   * @returns {Promise<void>}
   */
  const execStatusUpdateApi = async () => {
    let statusUpdateEvent = { response: true };
    let events = [];
    const sendStatus = STATUS_DELIVERED;
    let sendDamaged = damaged;
    let sendActualPackageDropPlace;
    let sendDeliveryBoxNumber;
    let sendDeliveryBoxPin;
    let blob1;
    let blob2;

    if (actualPackageDropPlace !== null) {
      sendActualPackageDropPlace = actualPackageDropPlace;
    } else {
      sendActualPackageDropPlace = deliveryPackage.packageDropPlace;
    }

    if (sendActualPackageDropPlace === DropOffLocation.LOCKER) {
      lockerNumber ? (sendDeliveryBoxNumber = lockerNumber) : "";
      lockerPin ? (sendDeliveryBoxPin = lockerPin) : "";
    }

    if (
      photoFileForProofOfDelivery &&
      sendActualPackageDropPlace !== DropOffLocation.HANDOVER
    ) {
      blob1 = photoFileForProofOfDelivery;
    }
    let deliveredEvent = {
      trackingNumber: deliveryPackage.trackingNumber,
      status: sendStatus,
      actualPackageDropPlace: sendActualPackageDropPlace,
      deliveryBoxNumber: sendDeliveryBoxNumber,
      deliveryBoxPin: sendDeliveryBoxPin,
      updateTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
      isIncrementDeliveryAttempt: true,
    };
    if (sendDamaged !== undefined) {
      deliveredEvent.damaged = sendDamaged;
    }
    if (deliveryPackage.isManuallyInputted) {
      deliveredEvent.isManuallyInputted = true;
    }
    events.push(deliveredEvent);
    statusUpdateEvent["events"] = events;
    blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });

    const body = new FormData();
    if (blob1) {
      body.append("delivered-photo", blob1);
    }
    body.append("status-update-event", blob2);
    const serialized = [...body];
    offlineBackendApi.updateShipmentStatus(serialized);
  };

  /**
   * 配達完了手続を実行する
   */
  async function statusUpdate() {
    await execStatusUpdateApi();
    toast.info($_("message.deliveryCompleted"));

    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();

    initDisplay();
  }

  /**
   * ヘルプページの表示を切り替える
   */
  function toggleHelpPage() {
    helpPageOpen = !helpPageOpen;
  }

  /**
   * スキャン開始前の状態に戻る
   */
  function cancel() {
    qrCodeScanner?.stopScanning();

    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();

    initDisplay();
  }

  /**
   * 配達登録画面の表示を初期化する
   */
  function initDisplay() {
    deliveryPackage = null;
    deliveryTarget.set(null);
    actualPackageDropPlace = null;
    lockerNumber = null;
    lockerPin = null;
    photoFileForProofOfDelivery = null;
    photoDataUrlForProofOfDelivery = null;
    damaged = false;
    qrCodeScanCompleted = false;
    qrCodeScanInProgress = false;
  }
</script>

<!--
        セクション1. 荷物のスキャンによる更新対象の特定
        -->
{#if !qrCodeScanInProgress && !qrCodeScanCompleted}
  <section class="qrCodeVerificationArea" out:fade>
    <p class="img">
      <img src={sceneUnloadingPackage} alt="業務イメージ" />
    </p>
    <button
      class="qrButton"
      on:click={loadingProgress.wrapAsync(async () => {
        qrCodeScanInProgress = true;
        await initAudioContext();
        await qrCodeScanner.startScanning();
      })}>荷物をスキャン</button
    >
  </section>
{/if}
{#if qrCodeScanInProgress}
  <QrCodeScanner
    bind:this={qrCodeScanner}
    onScanSuccessHandler={onScanSuccess}
    onScanErrorHandler={onScanError}
    enableInputForm={true}
  />
{/if}

<!--
        セクション2. 配達基本情報
        -->

{#if qrCodeScanCompleted}
  <div class="lateralMarginWrapper">
    <section class="deliveryInfoArea">
      <table class="infoTable">
        <caption class="infoCaption">配達基本情報</caption>
        <tr>
          <th>送り状番号</th>
          <td>{formatTrackingNumber(deliveryPackage.trackingNumber)}</td>
        </tr>
        {#if deliveryPackage.packageDropPlace != null}
          <tr>
            <th>受け渡し</th>
            <td
              >{$_(
                `classes.deliveryMethod.${deliveryPackage.packageDropPlace}`,
              )} 希望</td
            >
          </tr>
        {/if}
      </table>
      {#if deliveryPackage.cashOnDeliveryRequred}
        <div class="cashOnDeliveryNotice">
          <p>
            <span
              >代引き指定のお荷物です。<br
              />代引き料金を必ず受け取ってください。</span
            >
          </p>
        </div>
      {/if}
    </section>

    <!--
        セクション3. 配達完了手続
        -->
    <div class="border" />

    <div class="sectionTitle shippingAreaCaption">
      <span class="material-icons">real_estate_agent</span>
      <span class="title">配達完了手続</span>
    </div>

    <section class="shippingArea">
      <!-- 受け渡し方法の選択 -->
      <DeliveryCompletionProcedure
        bind:actualPackageDropPlace
        bind:lockerNumber
        bind:lockerPin
      />

      <div class="border" style="margin-top: 15px;" />

      <!-- アップロードボタン -->
      {#if [/** @type {number} */ (DropOffLocation.BY_THE_DOOR), DropOffLocation.LOCKER, DropOffLocation.METERBOX, DropOffLocation.MAILBOX].includes(actualPackageDropPlace)}
        <div class="uploadArea">
          <label class="uploadBtn">
            置き配の写真を撮影する
            <input
              type="file"
              capture="environment"
              accept="image/jpeg"
              id="photoInput"
              on:change={photoChange}
            />
          </label>
          <button class="helpBtn" on:click={toggleHelpPage}>
            <div style="width: 30px;">
              <span class="material-icons md-dark md-24">info_outline</span>
            </div>
          </button>
        </div>

        <!-- 写真撮影のポイント -->
        <div class="photo" style="display: {helpPageOpen ? 'block' : 'none'}">
          <div class="photoContentWrap">
            <button class="photoCloseLabel" on:click={toggleHelpPage}>×</button>
            <div class="photoContent">
              <div class="photoArea">
                <div class="photoTitle">
                  <div class="icons" style="width: 30px; margin-right: 5px">
                    <span class="material-icons md-dark md-24"
                      >help_outline</span
                    >
                  </div>
                  <h3>写真撮影のポイント</h3>
                </div>
                <p class="photoSample">
                  <img src={sample} alt="sample" style="width: 250px;" />
                </p>
                <ul class="photoCaption">
                  <li>荷物が映っている。</li>
                  <li>
                    指定場所に置かれたことを確認できるよう、周囲（壁、床、地面）が映っている。
                  </li>
                  <li>
                    荷物からの距離は2～3mくらいが目安。（ぽつんと置かれている感が出る）
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <label for="photoClose">
            <div class="photoBackground" />
          </label>
        </div>

        <!-- プレビュー画面（ファイルがアップロードされた時のみ表示） -->
        {#if photoDataUrlForProofOfDelivery}
          <div class="previewArea">
            <div class="previewImg">
              <img
                src={photoDataUrlForProofOfDelivery}
                alt="preview"
                style="width: 80%;"
              />
            </div>
          </div>
        {/if}

        <div class="border" />
      {/if}

      <!-- 確認・登録 -->
      <div class="damageCheck">
        <FormField>
          <Checkbox bind:checked={damaged} />
          <span slot="label">配達中に荷物が汚損した場合はチェックON</span>
        </FormField>
      </div>
      <Button
        class="registerButton"
        style="background-color={disallowGoAheadToDeliveryCompletion
          ? '#b1e6e5'
          : '#018786'}"
        variant="unelevated"
        bind:disabled={disallowGoAheadToDeliveryCompletion}
        on:click={statusUpdate}>配達を完了する</Button
      >
    </section>
  </div>
{/if}

<!--
        セクション4. フローティングアクションボタン
        -->
{#if qrCodeScanInProgress || qrCodeScanCompleted}
  <section class="floatingActionButtonArea">
    <button class="backBtn" on:click={cancel}>中止</button>
  </section>
{/if}

<style lang="scss">
  /*
   * セクション1. QRコードスキャンによる更新対象の特定
   */
  .qrCodeVerificationArea {
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p.img {
      text-align: center;

      img {
        width: calc(100% - 40px);
      }
    }

    .qrButton {
      margin-top: 40px;
      padding: 14px 30px;
      color: #fff;
      background-color: #018786;
      border: none;
      border-radius: 10px;
      font-size: 17px;
      width: 80%;
      max-width: 350px;
    }
  }

  .lateralMarginWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 20px 10px;
  }

  .sectionTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #018786;

    span {
      font-size: 18px;
    }

    .title {
      font-weight: bold;
    }
  }

  .border {
    width: 90%;
    margin: 20px auto;
    border-bottom: 1px solid #999;
  }

  /*
   * セクション2. 配達基本情報
   */
  .deliveryInfoArea {
    padding: 6px;
    background-color: #fff;
    border-radius: 10px;

    .infoTable {
      border-collapse: separate;
      border-spacing: 6px;
      width: 100%;

      .infoCaption {
        margin: 5px 0;
        font-weight: bold;
      }
      th {
        padding: 6px;
        width: 80px;
        background-color: #e7f4f4;
        vertical-align: middle;
      }
      td {
        position: relative;
        text-align: left;
        vertical-align: middle;
      }
    }
  }

  .cashOnDeliveryNotice {
    text-align: center;
    margin: 2px 5.5px 4px;
    padding: 5px 0;
    background-color: rgb(250, 220, 215);
    color: red;
    font-size: 13px;
    line-height: 18px;
  }

  /*
   * セクション3. 配達完了手続
   */
  .shippingArea {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 7px;
    padding: 15px 10px;

    .border {
      margin: 10px auto;
    }

    /* アップロードボタン */
    .uploadArea {
      position: relative;

      .uploadBtn {
        display: inline-block;
        font-size: 16px;
        padding: 14px 30px;
        color: #fff;
        background-color: #018786;
        border: none;
        border-radius: 10px;
      }

      input[type="file"] {
        display: none;
      }
      .helpBtn {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border: none;
        border-radius: 50%;
        padding: 0;
        margin: 0;
      }
    }

    /* 写真撮影のポイント */
    .photo {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: none;

      .photoContentWrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 350px;
        background-color: #fefefe;
        z-index: 2;
        border-radius: 5px;
      }
      .photoCloseLabel {
        background-color: #777;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 20px;
        width: 36px;
        height: 36px;
        line-height: 1.5;
        text-align: center;
        display: table-cell;
        position: fixed;
        top: -15px;
        right: -2%;
        z-index: 99999;
        font-size: 1.4em;
        cursor: pointer;
        padding: 0;
        margin: 0;
      }
      .photoContent {
        max-height: 60vh;
        overflow-y: auto;
        padding: 30px 45px;
      }
      .photoArea {
        position: relative;
        margin: 0 auto;
        padding: 20px 0;
        background-color: #fff;
        border-radius: 10px;
      }
      .photoTitle {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .photoTitle h3 {
        font-size: 18px;
      }
      .photoSample {
        margin: 20px auto;
      }
      .photoCaption {
        font-size: 14px;
        text-align: left;
        padding-left: 10px;
        list-style-type: circle;
      }
      .photoCaption li {
        padding-top: 10px;
      }
      .photoBackground {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        z-index: 1;
      }
    }

    /* プレビュー画面 */
    .previewArea {
      width: 94%;
      margin: 20px auto 0;
      padding: 20px 0;
      background-color: #ddd;
      border-radius: 10px;
    }

    .damageCheck label span {
      letter-spacing: 0;
    }

    :global(.registerButton) {
      min-width: 170px;
      height: 50px;
      margin-top: 10px;
      color: #fff;
    }
  }

  /*
   * セクション4. フローティングアクションボタン
   */
  .floatingActionButtonArea {
    button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      font-weight: bold;
      color: #fff;
    }

    .backBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      left: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
</style>
